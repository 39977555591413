<template>
  <div class="contact-map">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <iframe
            class="contact-map-size"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3349.51492696583!2d-96.74615178397967!3d32.910991084447424!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864c1ff0d5d2db45%3A0xdd13c0887fbfef8b!2s11837%20Judd%20Ct%2C%20Dallas%2C%20TX%2075243%2C%20USA!5e0!3m2!1sen!2sca!4v1652730136295!5m2!1sen!2sca"
            allowfullscreen=""
            loading="lazy"
          >
          </iframe>
        </div>
      </div>
    </div>
  </div>
</template>
